import { usePageQuery } from 'generated/graphql';
import { useMe } from 'hooks/useMe';
import NextError from 'pages/_error';
import { PageModule } from './PageModule';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { FEATURE_TOGGLE_DAILIES_ON_HOME_PAGE } from 'constants/featureToggle.constants';
import dynamic from 'next/dynamic';

const Dailies = dynamic(() => import('./Dailies').then(m => m.Dailies), { ssr: false });

export const ModuleStartPage = () => {
  const { adminUserType } = useMe();
  const showDailies = useFeatureToggle(FEATURE_TOGGLE_DAILIES_ON_HOME_PAGE).isEnabled;

  const modulePage = usePageQuery({
    variables: { slug: '/', previewUserType: adminUserType },
    fetchPolicy: adminUserType ? 'network-only' : 'cache-first',
  });

  if (modulePage.loading) {
    return null;
  }

  if (!modulePage.data?.page) {
    return <NextError statusCode={404} />;
  }
  const { page } = modulePage.data;

  return (
    <>
      {showDailies && <Dailies />}
      {page.modules.map((module, index) => (
        <PageModule module={module} key={module.__typename + index} index={index} />
      ))}
    </>
  );
};
