import { MainLayout } from 'views/global/MainLayout';
import { ModuleStartPage } from 'views/pages/start';

export const DAO_FEED_DESCRIPTION = 'Be among the first to create your own game with a brand new aesthetic!';
export const DAO_FEED_TITLE = 'Latest Worlds';

const FeedPage = () => {
  return (
    <MainLayout fullWidth noHorizontalPadding>
      <ModuleStartPage />
    </MainLayout>
  );
};

export default FeedPage;
