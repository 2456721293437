import { Discord } from '@hiberworld/icons';
import { Button } from 'components/common/buttons/Button';
import { PageContentWrapper } from 'components/layouts/PageContentWrapper/PageContentWrapper';
import { Heading1, Heading3, Paragraph } from 'components/typography';
import { config } from 'config';
import { useSearchParam } from 'lib/useSearchParam';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { TOPBAR_HEIGHT } from 'views/global/TopBar/TopBar.styles';

export const ErrorPage = ({ statusCode = 500 }: { statusCode?: number }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  // Add to hide chat and mobile login since they would cover some links
  const params = new URLSearchParams(searchParams?.toString());
  params.set('hideMobileLogin', 'true');
  params.set('hideChat', 'true');

  useEffect(() => {
    const targetUrl = `${pathname}?${params.toString()}`;
    const currentUrl = window.location.pathname + window.location.search;

    // only replace the url if the query param have been added
    if (currentUrl !== targetUrl) {
      router.replace(targetUrl);
    }
  }, []);

  const hideNavigation = useSearchParam('hideNavigation') === 'true';

  return (
    <div className="relative bg-gradient-to-t from-greyscale-hiberBlack from-10% via-greyscale-veryDark via-50% to-greyscale-almostBlack to-80% flex flex-row overflow-x-hidden">
      <PageContentWrapper
        className="relative w-full h-[100dvh] medium:max-h-[712px] max-h-[685px] z-[1]"
        style={{ height: hideNavigation ? '' : `calc(100vh - ${TOPBAR_HEIGHT}px)` }}>
        <div className="flex flex-col size-full pt-8 [@media(min-height:460px)]:medium:pt-[102px] medium:pl-[88px] pl-[21px]">
          <div className="flex flex-row flex-wrap">
            <div className="z-30 pr-4">
              <Heading1>{statusCode}</Heading1>
              <Heading3 className="pt-4">Page Ninja'd Away!</Heading3>
              <Paragraph
                className="pt-4 tabletLandscape:max-w-[437px] medium:max-w-[323px] max-w-[297px] mobileBreak:pb-8 w-full"
                size="medium">
                Oh no! Sneaky ninjas have swiped this page into the digital shadows. While our cyber samurais track it
                down - dodge the shurikens and swiftly return to safety.
              </Paragraph>
              <div className="flex flex-shrink pt-8 breakMobile:pb-2 breakMobile:[@media(min-height:460px)]:py-8">
                <Link href="/" className="block">
                  <Button size="large" variety="primary">
                    GO BACK HOME
                  </Button>
                </Link>
              </div>
            </div>

            <div className="flex flex-grow justify-end mr-4">
              <div className="relative flex aspect-[0.66/1] h-full w-[30vw] min-h-[245px] min-w-[162px] max-h-[456px] max-w-[300px] mt-2 mediumLarge:mt-0 mediumLarge:-bottom-20">
                <Image
                  priority={true}
                  style={{ objectFit: 'contain' }}
                  src={`${config.cdn}/static/images/ninja_3_no_padding.png`}
                  fill
                  alt={''}
                />
                <div
                  className="pointer-events-none block absolute bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]
            from-greyscale-hiberBlack from-[-10%] brightness-75 to-transparent to-70% w-[320%] h-[80%] -bottom-28 -right-44 medium:-right-64 -rotate-6 z-10
            "
                />
              </div>
            </div>
          </div>
          <div className="relative z-20 flex flex-col flex-grow justify-end pb-8 pt-4">
            <Paragraph size="small" className="text-greyscale-lightGrey">
              Need help? Check out our{' '}
              <Link target="_blank" href="https://www.hiber3d.com/faq">
                FAQ
              </Link>{' '}
              or{' '}
              <Link target="_blank" href="https://hiberworld.com/contact">
                Contact Us.
              </Link>
            </Paragraph>
            <div className="flex gap-2 items-center">
              <Discord color="white" size={24} />
              <Link target="_blank" href="https://discord.gg/hiber3d">
                <Paragraph size="small">Discord</Paragraph>
              </Link>
            </div>
          </div>
        </div>
      </PageContentWrapper>
    </div>
  );
};
